import React, { useRef, useEffect } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";

const VerticalVideo = () => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const videoUrl =
    "https://z-assets.s3.us-west-1.amazonaws.com/Art_District.mp4";
  const videoRef = useRef(null); // Only one videoRef needed now

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", playVideo);
    }

    // Attempt to play video immediately
    playVideo();

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadedmetadata", playVideo);
      }
    };
  }, []);

  const VideoBox = ({ videoRef }) => (
    <Box
      width="80%"
      position="relative"
      paddingTop="45%" // Reduced height for a less tall video (still maintains 16:9 ratio)
      overflow="hidden"
      boxShadow="2xl"
      borderRadius="lg"
      transition="all 0.3s"
      _hover={{ transform: "scale(1.05)", zIndex: 1 }}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        loop
        muted
        playsInline
        autoPlay
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the video covers the box while maintaining aspect ratio
        }}
      />
    </Box>
  );

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      py={8}
      minHeight="80vh"
    >
      <VideoBox videoRef={videoRef} />
    </Flex>
  );
};

export default VerticalVideo;
